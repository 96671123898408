import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/videos',
    name: 'Videos',
    component: () =>
      import(/* webpackChunkName: "videos" */ '../views/Videos.vue'),
  },
  {
    path: '/videos/:id',
    name: 'Video',
    component: () =>
      import(/* webpackChunkName: "video" */ '../views/Video.vue'),
  },
  {
    path: '/videos/:id/captions',
    name: 'Captions',
    component: () =>
      import(
        /* webpackChunkName: "edit-captions" */ '../views/VideoEditCaptions.vue'
      ),
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () =>
      import(/* webpackChunkName: "videos" */ '../views/Categories.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
