import _ from 'lodash'
import videoApi from '../../api/video'

const state = () => ({
  all: {},
  categories: [],
})

const getters = {}

const actions = {
  getVideos({ commit }) {
    videoApi.getVideos().then(videos => {
      commit('setVideos', _.keyBy(videos, '_id'))
    })
  },
  getVideo({ commit }, id) {
    return videoApi.getVideo(id).then(video => {
      commit('setVideo', video)
      return video
    })
  },
  getCategories({ commit }) {
    videoApi.getCategories().then(categories => {
      commit('setCategories', categories)
    })
  },
  createVideo({ commit }, videoId) {
    return videoApi.createVideo(videoId).then(video => {
      commit('setVideo', video)
    })
  },
  createCategory({ commit }, category) {
    return videoApi.createCategory(category).then(item => {
      commit('createCategory', item)
    })
  },
  updateVideo({ commit }, video) {
    videoApi.updateVideo(video).then(video => {
      commit('setVideo', video)
    })
  },
}

const mutations = {
  setVideos(state, videos) {
    state.all = videos
  },
  setVideo(state, video) {
    state.all = {
      ...state.all,
      [video._id]: video,
    }
  },
  setIsLoading(val) {
    state.isLoading = val
  },
  setCategories(state, categories) {
    state.categories = categories
  },
  createCategory(state, category) {
    state.categories.unshift(category)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
