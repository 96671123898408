import axios from '../config/axios'

export default {
  getVideos() {
    return axios.get('/videos').then(res => res.data)
  },
  getVideo(id) {
    return axios.get(`/videos/${id}`).then(res => res.data)
  },
  getCategories() {
    return axios.get('/categories').then(res => res.data)
  },
  createCategory(data) {
    return axios.post('/categories', data).then(res => res.data)
  },
  createVideo(videoId) {
    return axios.post('/videos', { videoId }).then(res => res.data)
  },
  updateVideo(video) {
    return axios.put(`/videos/${video._id}`, video).then(res => res.data)
  },
}
