import Vue from 'vue'
import Vuex from 'vuex'
import { sync } from 'vuex-router-sync'
import createLogger from 'vuex/dist/logger'

import router from '../router'
import video from './modules/video'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    video,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})

sync(store, router)

export default store
